<template>
  <div class="print-order-active-modal__main">
    <a-modal v-model="printOrderDetailVisible" width="950px" :footer="null" :title="null" :closable="false"
      :mask-closable="false" @cancel="printOrderDetailVisible = false">
      <div id="retailerOrderPrintMain" class="retailer-order-print">
        <div class="retailer-order-navigation">
          <span v-if="order.retailerReferenceId">{{
            `${$t("Retailers.RetailerOrder.referenceId")} #${order.retailerReferenceId}`
          }}</span><span v-if="role == 'admin'">{{
              `${$t("Retailers.RetailerOrder.batchId")} #${order.batchId}`
            }}</span>
        </div>
        <div class="retailer-order-id">
          {{ $t("Retailers.RetailerOrder.orderId") }}: {{ order.landingReferenceId }}
        </div>
        <div class="retailer-order-status-bar">
          <div class="download-box">
            <div class="order-status-print-and-download">
              <p v-if="
                !(
                  $route.name === 'adminBrandOrder' ||
                  $route.name === 'adminRetailerOrder' ||
                  $route.name === 'retailerOrder' ||
                  $route.name === 'brandOrder'
                )
              " class="download-content">
                <!--                <a-icon type="check-square"></a-icon>-->
                <span class="retailer-order-status-bar__item" @click="isMarkAs = !isMarkAs">&nbsp;<span
                    v-show="isMarkAs" class="print-tabs"><span v-for="tab in list" :key="tab.id"
                      :class="[!tab.canClick ? '' : 'can-not-click-mark-as']">{{ tab.name }}</span></span></span>
              </p>
              <p v-if="
                this.isAdmin &&
                $route.name !== 'adminBrandOrder' &&
                $route.name !== 'adminRetailerOrder'
              " class="download-content">
                <!--                <a-icon type="check-circle"></a-icon>-->
                <span class="retailer-order-status-bar__item"> </span>
                <!--                <a-icon type="down" :style="{ margin: '0px 4px' }"></a-icon>-->
              </p>
              <p class="download-content">
                <!--                <a-icon type="printer"></a-icon>-->
                <span class="retailer-order-status-bar__item" @click="clickPrint">
                  <span v-show="isPrint" class="print-tabs"></span>
                </span>
              </p>
              <p class="download-content">
                <!--                <a-icon type="download"></a-icon>-->
                <span class="retailer-order-status-bar__item download-tab-style"> </span>
              </p>
            </div>
          </div>
          <div v-if="order.status && order.status.id === orderStatus.deleted"
            class="retailer-order-status-bar__creation-date">
            {{ $t("Components.Retailers.Order.OrderStatus.deleteOrder") }}
          </div>
          <div v-if="order.status && order.status.id === orderStatus.pending && updatedStatusDate"
            class="retailer-order-status-bar__creation-date">
            {{ $t("Components.Retailers.Order.OrderStatus.orderCreatedOn") }}
            {{ $d(updatedStatusDate, "long", "en-US") }}
          </div>
          <div v-if="order.status && order.status.id === orderStatus.confirmed && updatedStatusDate"
            class="retailer-order-status-bar__creation-date">
            Order confirmed on {{ $d(updatedStatusDate, "long", "en-US") }}
          </div>
          <div v-if="
            order.status && order.status.id === orderStatus.supplyShipped && updatedStatusDate
          " class="retailer-order-status-bar__creation-date">
            Order shipped for supply on
            {{ $d(updatedStatusDate, "long", "en-US") }}
          </div>
          <div v-if="order.status && order.status.id === orderStatus.warehouse && updatedStatusDate"
            class="retailer-order-status-bar__creation-date">
            Order in warehouse on {{ $d(updatedStatusDate, "long", "en-US") }}
          </div>
          <div v-if="
            order.status && order.status.id === orderStatus.deliverShipped && updatedStatusDate
          " class="retailer-order-status-bar__creation-date">
            Order shipped for delivery on
            {{ $d(updatedStatusDate, "long", "en-US") }}
          </div>
          <div v-if="order.status && order.status.id === orderStatus.delivered && updatedStatusDate"
            class="retailer-order-status-bar__creation-date">
            Order delivered on {{ $d(updatedStatusDate, "long", "en-US") }}
          </div>
          <div v-if="
            order.status &&
            order.status.id === orderStatus.paidByRetailer &&
            (role === 'retailer' || role === 'admin') &&
            updatedStatusDate
          " class="retailer-order-status-bar__creation-date">
            Payment made on {{ $d(updatedStatusDate, "long", "en-US") }}
          </div>
          <div v-if="
            order.status &&
            order.status.id === orderStatus.paidToBrand &&
            (role === 'brand' || role === 'admin') &&
            updatedStatusDate
          " class="retailer-order-status-bar__creation-date">
            Payment made on {{ $d(updatedStatusDate, "long", "en-US") }}
          </div>
          <div class="retailer-order-status-bar__block">
            <div v-for="(item, index) in feLifecycle" :key="index" class="order-status-bar completed">
              <img v-if="item.complete && isRetailer" src="@/assets/img/orders/retailer-status-bar-lit.svg" /><img
                v-else-if="item.complete && isBrand" src="@/assets/img/orders/status-bar-lit.svg" /><img
                v-else-if="item.complete && isAdmin" src="@/assets/img/orders/admin-status-bar-lit.svg" /><img v-else
                src="@/assets/img/orders/status-bar-unlit.svg" /><span v-if="
                  (item.status.id == orderStatus.paidByRetailer ||
                    item.status.id == orderStatus.paidToBrand) &&
                  role != 'admin'
                ">Paid</span><span v-else-if="
                  (item.status.id == orderStatus.paidByRetailer ||
                    item.status.id == orderStatus.paidToBrand) &&
                  ($route.name === 'adminBrandOrder' || $route.name === 'adminRetailerOrder') &&
                  role === 'admin'
                ">Paid</span><span v-else>{{ item.status.name }}</span>
            </div>
          </div>
        </div>
        <div v-if="order.status" class="retailer-order-status-banner retailer-order-status-banner__block"
          :style="`background: ${bgColor} !important`">
          <div class="columns retailer-order-status-banner__left-block">
            <div class="column is-narrow retailer-order-status-banner__icon">
              <a-icon class="icon-tips-style" type="info-circle" theme="filled"
                :style="{ color: buttonColor }"></a-icon>
            </div>
            <div>
              <div class="retailer-order-status-banner__title">
                {{ differentStatusHasText.title }}
              </div>
              <div class="retailer-order-status-banner__details">
                {{ differentStatusHasText.content }}
              </div>
            </div>
          </div>
          <div v-if="
            order.status &&
            differentStatusHasText.buttonList &&
            differentStatusHasText.buttonList.length !== 0
          " class="retailer-order-status-banner__button-block">
            <div v-for="(list, index) in differentStatusHasText.buttonList" :key="index" class="button-main-style">
              <button v-if="list.isAccept" class="button retailer-settings__saved-addresses-button button-new-color"
                :style="`background: ${buttonColor} !important`" @click="acceptOrder(list.status)">
                {{ list.name }}
              </button>
              <button v-else-if="list.isEdit" class="button retailer-settings__saved-addresses-button button-new-color"
                :style="`background: ${buttonColor} !important`" :disabled="list.canNotClick">
                {{ list.name }}
              </button>
              <button v-else-if="list.isAddShip"
                class="button retailer-settings__saved-addresses-button button-new-color"
                :style="`background: ${buttonColor} !important`" :disabled="list.canNotClick">
                {{ list.name }}
              </button>
              <button v-else-if="list.isShowAdvice"
                class="button retailer-settings__saved-addresses-button button-new-color"
                :style="`background: ${buttonColor} !important`" :disabled="list.canNotClick">
                {{ list.name }}
              </button>
              <button v-else-if="list.isDelete"
                class="button retailer-settings__saved-addresses-button button-new-color"
                :style="`background: ${buttonColor} !important`" :disabled="list.canNotClick"
                @click="statusTransition(order.status.id, list.status)">
                {{ list.name }}
              </button>
              <button v-else-if="list.isShipped"
                class="button retailer-settings__saved-addresses-button button-new-color"
                :style="`background: ${buttonColor} !important`" :disabled="list.canNotClick"
                @click="statusTransition(order.status.id, list.status)">
                {{ list.name }}
              </button>
              <button v-else class="button retailer-settings__saved-addresses-button button-new-color"
                :style="`background: ${buttonColor} !important`"
                @click="statusTransition(order.status.id, list.status, list.isDecline)">
                {{ list.name }}
              </button>
            </div>
          </div>
        </div>
        <div class="columns order-detail-content-table-info">
          <div class="column is-two-thirds retailer-order-table__container">
            <div class="columns is-multiline">
              <div class="column is-12">
                <div class="retailer-order-table table-border-b-none">
                  <div class="retailer-order-table__top">
                    <p class="retailer-order-table__name">
                      {{ $t("Retailers.RetailerOrder.products") }}
                    </p>
                  </div>
                  <table class="table is-fullwidth">
                    <thead>
                      <tr>
                        <th class="retailer-order-table__head" style="width: 18%">
                          {{ $t("Retailers.RetailerOrder.sKU") }}
                        </th>
                        <th class="retailer-order-table__head" width="20%">Name</th>
                        <th class="retailer-order-table__head">
                          {{ $t("Retailers.RetailerOrder.quantity") }}
                        </th>
                        <th class="retailer-order-table__head">
                          {{ $t("Retailers.RetailerOrder.testers") }}
                        </th>
                        <th class="retailer-order-table__head">
                          {{ $t("Retailers.RetailerOrder.mSRP") }}
                        </th>
                        <th class="retailer-order-table__head" width="20%">
                          {{ $t("Retailers.RetailerOrder.unitPrice") }}
                        </th>
                        <th class="retailer-order-table__head" :class="{ alignTextCenter: alignCenter }">
                          {{ $t("Retailers.RetailerOrder.amount") }}
                        </th>
                        <th v-if="isProductEditable" class="retailer-order-table__head"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in purchaseDetails" :key="index">
                        <td class="retailer-order-table__cell" :style="{
                          color: `${item.noteType && item.noteType.id === 'oos' ? '#bfbfbf' : '#262626'
                            }!important`
                        }">
                          {{ item.product && item.product.sku }}
                          <p v-if="item.adjusterByClientType" class="edit-params-product"
                            :style="{ color: item.textColor, marginTop: '12px' }">
                            {{ `${item.adjusterByClientType.name} Edit` || "" }}
                          </p>
                        </td>
                        <td class="retailer-order-table__cell" :style="{
                          color: `${item.noteType && item.noteType.id === 'oos' ? '#bfbfbf' : '#262626'
                            }!important`
                        }">
                          {{ item.product && item.product.name }}
                        </td>
                        <td class="retailer-order-table__cell" :style="{
                          color: `${item.noteType && item.noteType.id === 'oos' ? '#bfbfbf' : '#262626'
                            }!important`
                        }">
                          {{ item.quantity }}
                          <p v-if="item.adjusterByClientType" class="edit-params-product" :style="{
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: '12px',
                            color: item.textColor
                          }">
                            {{ item.adjustedQuantity || 0 }}
                            <a-tooltip v-if="item.noteType && Object.keys(item.noteType).length !== 0" placement="right"
                              :title="item.noteType.name">
                              <a-icon v-if="item.noteType && Object.keys(item.noteType).length !== 0" type="info-circle"
                                :style="{ fontSize: '12px', marginLeft: '5px', color: '#bfbfbf' }"></a-icon>
                            </a-tooltip>
                          </p>
                        </td>
                        <td class="retailer-order-table__cell" :style="{
                          color: `${item.noteType && item.noteType.id === 'oos' ? '#bfbfbf' : '#262626'
                            }!important`
                        }">
                          {{ item.testersquantity || 0 }}
                          <p v-if="item.adjusterByClientType" class="edit-params-product"
                            :style="{ marginTop: '12px', color: item.textColor }">
                            {{ item.adjustedTestersQuantity || 0 }}
                          </p>
                        </td>
                        <td class="retailer-order-table__cell" :style="{
                          color: `${item.noteType && item.noteType.id === 'oos' ? '#bfbfbf' : '#262626'
                            }!important`
                        }">
                          {{ priceFormat(item.recordedMsrpUsd) }}
                        </td>
                        <td class="retailer-order-table__cell" :style="{
                          color: `${item.noteType && item.noteType.id === 'oos' ? '#bfbfbf' : '#262626'
                            }!important`
                        }">
                          {{ priceFormat(item.calculatedWholesalePriceUsd) }}
                        </td>
                        <td class="retailer-order-table__cell" :class="{ alignTextCenter: alignCenter }" :style="{
                          color: `${item.adjustedQuantity ? item.textColor : ''} !important`
                        }">
                          {{ priceFormat(item.calculatedWholesaleCostUsd) }}
                        </td>
                      </tr>
                      <tr>
                        <td class="retailer-order-table__cell table-border-b-none"></td>
                        <td class="retailer-order-table__cell table-border-b-none"></td>
                        <td class="retailer-order-table__cell table-border-b-none"></td>
                        <td class="retailer-order-table__cell table-border-b-none"></td>
                        <td class="retailer-order-table__cell table-border-b-none"></td>
                        <td class="retailer-order-table__cell table-border-b-none subtotal">
                          {{ $t("Retailers.RetailerOrder.itemSubtotal") }}
                        </td>
                        <td class="retailer-order-table__cell table-border-b-none"
                          :class="{ alignTextCenter: alignCenter }">
                          {{ priceFormat(productTotal) }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div v-if="retailerTermsName" class="additional-charge-tips-text">
                  <a-icon type="exclamation-circle" theme="filled"
                    :style="{ color: '#FEC206', marginRight: '10px' }"></a-icon>
                  {{ `${retailerTermsName} has added Retailer Terms` }}
                </div>
                <RetailerTerms :order="order" :button-color="buttonColor" :retailer-terms-total="retailerTermsTotal"
                  :retailer-lists="retailerLists" @getRetailerTermsTotal="getRetailerTermsTotal"></RetailerTerms>
                <div v-if="
                  (isAdmin || isRetailer) &&
                  showAdditionalCharge &&
                  additionalCharge &&
                  additionalCharge.length !== 0
                " class="additional-charge-tips-text">
                  <a-icon type="exclamation-circle" theme="filled"
                    :style="{ color: '#7ACE93', marginRight: '10px' }"></a-icon>
                  {{ `${additionalText} has added additional charges` }}
                </div>
                <AdditionalCharges v-if="showAdditionalCharge" :order="order" :is-show-print="true"></AdditionalCharges>
                <ShippingCharge :shipping-charge="shippingCharge"></ShippingCharge>
                <div class="total-amount-number">
                  <p>
                    <span>TOTAL</span><span>{{ sumTotal }}</span>
                  </p>
                  <p v-if="(isBrand || isAdmin) && $route.name !== 'adminRetailerOrder'">
                    <span class="download-estimated-payment">ESTIMATED PAYMENT</span>
                  </p>
                </div>
              </div>
              <div class="column is-12">
                <OrderActivity v-if="brand.members && retailer.members" :brand="brand" :retailer="retailer"
                  :order="order" :button-color="buttonColor" :is-show="true"></OrderActivity>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="columns is-multiline">
              <div v-if="
                supplyAirTrackingInfo ||
                supplyOceanTrackingInfo ||
                deliveryAirTrackingInfo ||
                deliveryOceanTrackingInfo
              " class="column is-12">
                <div class="retailer-order-information">
                  <div v-if="
                    (role == 'admin' || role == 'retailer') &&
                    (deliveryAirTrackingInfo || deliveryOceanTrackingInfo)
                  " class="retailer-order-information__container">
                    <div class="retailer-order-information__block">
                      <div class="shipping-first-block">
                        <div class="hipping-first-block__heading">
                          <div v-if="role == 'admin'" class="retailer-order-information__title">
                            {{ $t("Components.Orders.OrderDetails.deliveryShipmentInfo") }}
                          </div>
                          <div v-else class="retailer-order-information__title">
                            {{ $t("Components.Orders.OrderDetails.shipmentInfo") }}
                          </div>
                          <a v-if="role == 'admin'" class="edit-shipment-info"
                            @click="editTrackingInfo(orderStatus.deliverShipped)">{{
                              $t("Components.Orders.OrderDetails.edit") }}</a>
                        </div>
                        <div v-if="deliveryAirTrackingInfo">
                          <div class="retailer-order-information__header">
                            {{ $t("Components.Orders.OrderDetails.shippingCarrier") }}
                          </div>
                          <div class="address shipment-provider">
                            <p>{{ deliveryAirTrackingInfo.provider.name }}</p>
                          </div>
                          <div class="retailer-order-information__header">
                            {{ $t("Components.Orders.OrderDetails.trackingNumber") }}
                          </div>
                          <div class="retailer-order-information__link">
                            {{ deliveryAirTrackingInfo.trackingId }}
                          </div>
                        </div>
                        <div v-if="deliveryOceanTrackingInfo">
                          <div class="retailer-order-information__header">
                            {{ $t("Components.Orders.OrderDetails.forwarderName") }}
                          </div>
                          <div class="address shipment-provider">
                            <p>{{ deliveryOceanTrackingInfo.forwarderName }}</p>
                          </div>
                          <div class="retailer-order-information__header">
                            {{ $t("Components.Orders.OrderDetails.vesselCompany") }}
                          </div>
                          <div class="address shipment-provider">
                            <p>{{ deliveryOceanTrackingInfo.provider.name }}</p>
                          </div>
                          <div class="retailer-order-information__header">
                            {{ $t("Components.Orders.OrderDetails.houseBlNumner") }}
                          </div>
                          <div class="retailer-order-information__link">
                            {{ deliveryOceanTrackingInfo.houseBlNumber }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="
                    (role == 'admin' || role == 'brand') &&
                    (supplyAirTrackingInfo || supplyOceanTrackingInfo)
                  " class="retailer-order-information__container">
                    <div class="retailer-order-information__block">
                      <div class="shipping-first-block">
                        <div class="hipping-first-block__heading">
                          <div v-if="role == 'admin'" class="retailer-order-information__title">
                            {{ $t("Components.Orders.OrderDetails.supplyShipmentInfo") }}
                          </div>
                          <div v-else class="retailer-order-information__title">
                            {{ $t("Components.Orders.OrderDetails.shipmentInfo") }}
                          </div>
                          <!--                          <a-->
                          <!--                            v-if="role == 'brand' || role == 'admin'"-->
                          <!--                            class="edit-shipment-info"-->
                          <!--                            @click="editTrackingInfo(orderStatus.supplyShipped)"-->
                          <!--                            >{{ $t("Components.Orders.OrderDetails.edit") }}</a-->
                          <!--                          >-->
                        </div>
                        <div v-if="supplyAirTrackingInfo">
                          <div class="retailer-order-information__header">
                            {{ $t("Components.Orders.OrderDetails.shippingCarrier") }}
                          </div>
                          <div class="address shipment-provider">
                            <p>{{ supplyAirTrackingInfo.provider.name }}</p>
                          </div>
                          <div class="retailer-order-information__header">
                            {{ $t("Components.Orders.OrderDetails.trackingNumber") }}
                          </div>
                          <div class="retailer-order-information__link">
                            {{ supplyAirTrackingInfo.trackingId }}
                          </div>
                        </div>
                        <div v-if="supplyOceanTrackingInfo">
                          <div class="retailer-order-information__header">
                            {{ $t("Components.Orders.OrderDetails.forwarderName") }}
                          </div>
                          <div class="address shipment-provider">
                            <p>{{ supplyOceanTrackingInfo.forwarderName }}</p>
                          </div>
                          <div class="retailer-order-information__header">
                            {{ $t("Components.Orders.OrderDetails.vesselCompany") }}
                          </div>
                          <div class="address shipment-provider">
                            <p>{{ supplyOceanTrackingInfo.provider.name }}</p>
                          </div>
                          <div class="retailer-order-information__header">
                            {{ $t("Components.Orders.OrderDetails.houseBlNumner") }}
                          </div>
                          <div class="retailer-order-information__link">
                            {{ supplyOceanTrackingInfo.houseBlNumber }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column is-12">
                <div class="retailer-order-information">
                  <div v-if="isRetailer" class="retailer-order-information__container">
                    <div class="retailer-order-information__block">
                      <div class="shipping-first-block">
                        <div class="hipping-first-block__heading">
                          <div class="retailer-order-information__title">
                            {{ $t("Retailers.RetailerOrder.shipping") }}
                          </div>
                          <div v-if="!brand.logoAsset.url" class="img-block">
                            <img src="@/assets/img/default-logo-image.png" />
                          </div>
                          <div v-if="brand.logoAsset.url" class="img-block">
                            <img :src="brand.logoAsset.url" />
                          </div>
                        </div>
                        <div class="retailer-order-information__header">
                          {{ $t("Retailers.RetailerOrder.brand") }}
                        </div>
                        <div class="retailer-order-information__link">{{ brand.name }}</div>
                      </div>
                    </div>
                  </div>
                  <div v-if="isBrand" class="retailer-order-information__container">
                    <div class="retailer-order-information__block">
                      <div class="shipping-first-block">
                        <div class="hipping-first-block__heading">
                          <div class="retailer-order-information__title">
                            {{ $t("Retailers.RetailerOrder.shipping") }}
                          </div>
                          <div v-if="!retailer.logoAsset.url" class="img-block">
                            <img src="@/assets/img/default-logo-image.png" />
                          </div>
                          <div v-if="retailer.logoAsset.url" class="img-block">
                            <img :src="retailer.logoAsset.url" />
                          </div>
                        </div>
                        <div class="retailer-order-information__header">Buyer</div>
                        <div class="retailer-order-information__link">{{ retailer.name }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="retailer-order-information__container">
                    <div class="retailer-order-information__header">
                      <div class="order-information-subheading">
                        <span>{{ $t("Retailers.RetailerOrder.shippingAddress") }}</span>
                        <!--                        <a-icon-->
                        <!--                          v-if="showEditContactAndAddress"-->
                        <!--                          class="edit-shipment-info"-->
                        <!--                          type="edit"-->
                        <!--                        ></a-icon>-->
                      </div>
                      <div v-if="order.shipToAddress" class="adresss">
                        <p>{{ order.shipToAddress.addressee }}</p>
                        <p>{{ order.shipToAddress.streetAddressLine_1 }}</p>
                        <p>{{ order.shipToAddress.streetAddressLine_2 }}</p>
                        <p v-if="order.shipToAddress.city">
                          {{ order.shipToAddress.city }}, {{ order.shipToAddress.region }}
                          {{ order.shipToAddress.postalCode }}
                        </p>
                        <p v-if="order.shipToAddress.country">
                          {{ countryNameById(order.shipToAddress.country.id) }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="retailer-order-information__container">
                    <div class="retailer-order-information__header">
                      <div class="order-information-subheading">
                        <span>{{ $t("Retailers.RetailerOrder.contactInformation") }}</span>
                        <!--                        <a-icon-->
                        <!--                          v-if="showEditContactAndAddress"-->
                        <!--                          class="edit-shipment-info"-->
                        <!--                          type="edit"-->
                        <!--                        ></a-icon>-->
                      </div>
                      <div v-if="order.contact" class="adresss">
                        <p>{{ order.contact.name }}</p>
                        <p>{{ order.contact.phone }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="retailer-order-information__container">
                    <div class="retailer-order-information__header">
                      <div class="order-information-subheading">
                        <span>{{ $t("Retailers.RetailerOrder.shipDate") }}</span>
                        <!--                        <a-icon-->
                        <!--                          v-if="-->
                        <!--                            order.status &&-->
                        <!--                              order.status.id == orderStatus.pending &&-->
                        <!--                              !order.declined &&-->
                        <!--                              !(isRetailer && this.order.version === '2')-->
                        <!--                          "-->
                        <!--                          class="edit-shipment-info"-->
                        <!--                          type="edit"-->
                        <!--                        ></a-icon>-->
                      </div>
                      <div class="adresss">
                        <p>{{ order.shipDate }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="retailer-order-information__container">
                    <div class="retailer-order-information__header">
                      <div class="order-information-subheading">
                        <span>Cancel Date</span>
                        <!--                        <a-icon-->
                        <!--                          v-if="-->
                        <!--                            order.status &&-->
                        <!--                              order.status.id == orderStatus.pending &&-->
                        <!--                              !order.declined &&-->
                        <!--                              !(isRetailer && order.version === '2')-->
                        <!--                          "-->
                        <!--                          class="edit-shipment-info"-->
                        <!--                          type="edit"-->
                        <!--                        ></a-icon>-->
                      </div>
                      <div class="adresss">
                        <p>{{ order.cancelDate }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="retailer-order-information__container">
                    <div class="retailer-order-information__header">
                      {{ $t("Retailers.RetailerOrder.estimatedPaymentDate") }}
                      <div class="adresss">
                        <p>{{ order.calculatedPaidToBrandDate }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column is-12">
                <div class="retailer-order-information retailer-order-information__terms">
                  <div class="retailer-order-information__container">
                    <div class="retailer-order-information__title">
                      {{ $t("Retailers.RetailerOrder.terms") }}
                    </div>
                  </div>
                  <div class="retailer-order-information__container">
                    <div class="retailer-order-information__header">
                      {{ $t("Retailers.RetailerOrder.paymentTerms") }}
                      <div v-if="order.recordedRetailerPaymentTerm" class="adresss">
                        <p>{{ order.recordedRetailerPaymentTerm.name }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="retailer-order-information__container">
                    <div class="retailer-order-information__header">
                      {{ $t("Retailers.RetailerOrder.compliance") }}
                      <div class="adresss">
                        <div>
                          <p v-if="retailer.terms.productTicketingRequired">
                            {{ $t("Retailers.RetailerOrder.productTicketing") }}
                          </p>
                          <p v-if="retailer.terms.ediRequired">
                            {{ $t("Retailers.RetailerOrder.retailerEDI") }}
                          </p>
                          <p v-if="retailer.terms.routingGuideRequired">
                            {{ $t("Retailers.RetailerOrder.vendorGuide") }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="print-button">
          <a-button class="print-order-button" :style="{ background: '#4a8a5d', color: '#ffffff', marginRight: '15px' }"
            @click="printOrderModal()">Print</a-button>
          <a-button class="print-order-button" @click="closeOrderModal">Close</a-button>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import OrderActivity from "@/components/orders/OrderActivity"
import AdditionalCharges from "@/components/orders/AdditionalCharges"
import RetailerTerms from "@/components/orders/retailerTerms"
import constants from "@/config.js"
import { priceFormat } from "@/utils/validate"
import print from "print-js"
import ShippingCharge from "../../orders/ShippingCharge.vue"
// import printCss from '../../../../public/print-order.css'

export default {
  name: "PrintOrderDetailModal",
  components: {
    OrderActivity,
    AdditionalCharges,
    RetailerTerms,
    ShippingCharge
  },
  data() {
    return {
      print,
      printOrderDetailVisible: false,
      priceFormat,
      editShippingWindow: false,
      contactInfo: null,
      editContactInfo: false,
      showEditAddressModal: false,
      showTrackingInfo: false,
      initialQuantity: null,
      initialTesters: null,
      alignCenter: false,
      orderStatus: constants.orderStatus,
      showAddChargeFileds: false,
      purchaseDetails: [
        {
          calculatedRetailCostUsd: "",
          calculatedWholesaleCostUsd: "",
          calculatedWholesalePriceUsd: "",
          createdAt: "",
          createdBy: "",
          product: {
            id: "",
            name: "",
            sku: ""
          },
          quantity: "",
          adjustedQuantity: "",
          quantityType: {
            id: "",
            name: ""
          },
          testersquantity: "",
          editTesterValue: "",
          adjustedTestersQuantity: 0,
          recordedMargin: "",
          recordedMsrpUsd: "",
          noteType: {
            id: ""
          },
          textColor: ""
        }
      ],
      itemIndex: -1,
      editingIndex: -1,
      isEditing: false,
      productIndex: -1,
      productEditIndex: -1,
      isSaveDisabled: true,
      trackingInfo: [],
      isEditTrackingInfo: false,
      airShipments: [],
      oceanShipments: [],
      trackingStatus: "",
      editProductList: [],
      trtailerTermsTotal: 0,
      retailerLists: [],
      retailerTermsTotal: 0,
      // order-status
      isPrint: false,
      isShowDownloadDropDown: false,
      isMarkAs: false,
      markAsTabText: [
        {
          id: 1,
          name: "Pending",
          status: constants.orderStatus.pending,
          nextStatus: constants.orderStatus.confirmed,
          reStatus: null,
          canClick: true
        },
        {
          id: 2,
          name: "Confirmed",
          status: constants.orderStatus.confirmed,
          nextStatus: constants.orderStatus.delivered,
          reStatus: null,
          canClick: true
        },
        {
          id: 3,
          name: "Shipped",
          status: constants.orderStatus.delivered,
          nextStatus: constants.orderStatus.paidByRetailer,
          reStatus: null,
          canClick: true
        },
        {
          id: 4,
          name: "Invoice",
          status: constants.orderStatus.invoiced,
          reStatus: constants.orderStatus.delivered,
          nextStatus: constants.orderStatus.paidByRetailer,
          canClick: true
        },
        {
          id: 5,
          name: "Paid by Retailer",
          status: constants.orderStatus.paidByRetailer,
          reStatus: constants.orderStatus.invoiced,
          nextStatus: constants.orderStatus.paidToBrand,
          canClick: true
        },
        {
          id: 6,
          name: "Paid to Brand",
          status: constants.orderStatus.paidToBrand,
          reStatus: constants.orderStatus.paidByRetailer,
          nextStatus: "",
          canClick: true
        }
      ],
      isDownloadLoading: false,
      list: []
    }
  },
  computed: {
    showEditContactAndAddress() {
      return (
        this.order.status &&
        this.order.status.id == this.orderStatus.pending &&
        this.isRetailer &&
        !this.order.declined
      )
    },
    showEditShippingWindow() {
      return (
        this.order.status &&
        this.order.status.id == this.orderStatus.pending &&
        this.isBrand &&
        !this.order.declined
      )
    },
    supplyAirTrackingInfo() {
      return (
        this.order.airShipments &&
        this.order.airShipments.filter(
          item => item.orderStatus.id === this.orderStatus.supplyShipped
        )[0]
      )
    },
    supplyOceanTrackingInfo() {
      return (
        this.order.oceanShipments &&
        this.order.oceanShipments.filter(
          item => item.orderStatus.id === this.orderStatus.supplyShipped
        )[0]
      )
    },
    deliveryAirTrackingInfo() {
      return (
        this.order.airShipments &&
        this.order.airShipments.filter(
          item => item.orderStatus.id === this.orderStatus.deliverShipped
        )[0]
      )
    },
    deliveryOceanTrackingInfo() {
      return (
        this.order.oceanShipments &&
        this.order.oceanShipments.filter(
          item => item.orderStatus.id === this.orderStatus.deliverShipped
        )[0]
      )
    },
    showTrackingInfoButton() {
      return (
        this.role == "brand" &&
        (this.statusItemsNames.indexOf(this.orderStatus.supplyShipped) <=
          this.statusItemsNames.indexOf(this.order.status.id) ||
          this.order.status.id == this.orderStatus.paidByRetailer) &&
        !this.supplyAirTrackingInfo &&
        !this.supplyOceanTrackingInfo
      )
    },
    statusItemsNames() {
      if (!this.order) {
        return false
      }
      return this.order.lifecycle && Object.values(this.order.lifecycle).map(item => item.status.id)
    },
    isProductEditable() {
      return (
        this.order.status &&
        (this.role === "brand" || this.$route.name === "adminBrandOrder") &&
        this.order.status.id === this.orderStatus.pending &&
        !this.isEditing
      )
    },
    role() {
      return this.$store.getters["user/role"]
    },
    isRetailer() {
      return this.role === "retailer" || constants.adminRetailerRoutes.includes(this.$route.name)
    },
    isBrand() {
      return this.role === "brand" || constants.adminBrandRoutes.includes(this.$route.name)
    },
    order() {
      return this.$store.getters["order/order"]
    },
    retailer() {
      return this.$store.getters["retailer/retailer"]
    },
    brand() {
      return this.$store.getters["brand/brand"]
    },
    countries() {
      return this.$store.getters["reference/countries"]
    },
    isAdmin() {
      return this.$store.getters["user/isAdmin"]
    },
    getProductByIds() {
      return this.$store.getters["product/productsForCart"]
    },
    retailerOrBrandOrAdminInfo() {
      if (this.isBrand) {
        return this.$store.getters["brand/brand"]
      } else if (this.isRetailer) {
        return this.$store.getters["retailer/retailer"]
      } else {
        return this.$store.getters["admin/admin"]
      }
    },
    additionalText() {
      let result = []
      if (this.isBrand) {
        result =
          this.order.charges &&
          this.order.charges.filter(
            item => item.seenByBrand && item.ownedBy.id === "brand" && item.paidBy.id === "retailer"
          )
      } else if (this.isRetailer) {
        result =
          this.order.charges &&
          this.order.charges.filter(
            item =>
              item.seenByRetailer && item.ownedBy.id === "brand" && item.paidBy.id === "retailer"
          )
      } else {
        result =
          this.order.charges &&
          this.order.charges.filter(
            item =>
              item.seenByLanding && item.ownedBy.id === "brand" && item.paidBy.id === "retailer"
          )
      }
      if (
        result &&
        result.length !== 0 &&
        result[0].addedBy &&
        result[0].addedBy.id === "landing"
      ) {
        return "Landing"
      } else {
        return this.brand.name
      }
    },
    retailerTermsName() {
      let retailerTerms = ""
      const reatailerCharge = this.order.charges.filter(
        item =>
          item.ownedBy.id === "retailer" &&
          item.paidBy.id === "brand" &&
          item.chargeType.id === "custom"
      )
      if (reatailerCharge && reatailerCharge.length !== 0) {
        if (reatailerCharge[0].addedBy.id === "landing") {
          retailerTerms = "Landing"
        } else if (reatailerCharge[0].addedBy.id === "retailer") {
          retailerTerms = this.retailer.name
        }
      }
      return retailerTerms
    },
    bgColor() {
      if (this.isBrand) {
        return "rgba(74, 138, 93, 0.11)"
      } else if (this.isRetailer) {
        return "#FEF2E2"
      } else {
        return "#FFFAF9"
      }
    },
    buttonColor() {
      if (this.isBrand) {
        return "#4a8a5d"
      } else if (this.isRetailer) {
        return "#FEC206"
      } else {
        return "#FF6132"
      }
    },
    showAdditionalCharge() {
      if (this.isRetailer) {
        const list =
          this.order &&
          this.order.charges &&
          this.order.charges.filter(
            item =>
              item.seenByRetailer && item.ownedBy.id === "brand" && item.paidBy.id === "retailer"
          )
        return list && list.length !== 0
      }
      return true
    },
    // additionalCharges() {
    //   if (this.isBrand) {
    //     return this.order.charges && this.order.charges.filter(item => item.seenByBrand)
    //   } else if (this.isRetailer) {
    //     return this.order.charges && this.order.charges.filter(item => item.seenByRetailer)
    //   } else {
    //     return this.order.charges
    //   }
    // },
    totalAdditionalCharge() {
      if (this.additionalCharge && this.additionalCharge.length !== 0) {
        return this.additionalCharge.reduce((total, charge) => {
          if (charge.paidBy.id == "brand") {
            return total - parseFloat(charge.calculatedCostUsd)
          } else {
            return total + parseFloat(charge.calculatedCostUsd)
          }
        }, 0)
      } else {
        return 0
      }
    },
    additionalCharge() {
      if (this.isBrand) {
        return (
          this.order.charges &&
          this.order.charges.filter(
            item => item.seenByBrand && item.ownedBy.id === "brand" && item.paidBy.id === "retailer"
          )
        )
      } else if (this.isRetailer) {
        return (
          this.order.charges &&
          this.order.charges.filter(
            item =>
              item.seenByRetailer && item.ownedBy.id === "brand" && item.paidBy.id === "retailer"
          )
        )
      } else {
        return (
          this.order.charges &&
          this.order.charges.filter(
            item =>
              item.seenByLanding && item.ownedBy.id === "brand" && item.paidBy.id === "retailer"
          )
        )
      }
    },
    productTotal() {
      let list = this.editProductList
      if (list && list.length === 0) {
        list = this.orderDetails()
      }
      if (!list) {
        list = []
      }
      return list.reduce((total, charge) => {
        // if (charge.paidBy.id == "brand") {
        //   return total - parseFloat(charge.calculatedCostUsd)
        // } else {
        return total + Number(charge.calculatedWholesaleCostUsd)
        // }
      }, 0)
    },
    calculatedTotal() {
      let retailerValue, chargeValue
      if (this.retailerTermsTotal < 0) {
        retailerValue = this.retailerTermsTotal * -1
      } else {
        retailerValue = this.retailerTermsTotal
      }
      if (this.additionalCharge && this.additionalCharge.length !== 0) {
        chargeValue = this.additionalCharge[0].percentage
          ? this.additionalCharge[0].calculatedCostUsd
          : this.additionalCharge[0].recordedValue
      } else {
        chargeValue = 0
      }

      const first = eval(this.productTotal - retailerValue + Number(chargeValue))
      return first;
    },
    sumTotal() {
      var total = this.calculatedTotal || 0;
      var shippingCharge = this.shippingCharge || 0;
      return this.priceFormat(total + shippingCharge)
    },
    shippingCharge() {
      var calculateTotal = this.calculatedTotal
      if (!this.order || !calculateTotal) {
        return undefined;
      }
      var logisticTerms = this.brand.logisticTerms.filter((logisticTerm) => {
        return logisticTerm.retailerId === "*"
          || logisticTerm.retailerId === this.order.retailer.id
      });

      var shippingChargePercentages = 0;

      if (logisticTerms.length > 1) {
        logisticTerms = logisticTerms.filter(l => l.retailerId !== '*');
      }
      console.log(logisticTerms.length);
      if (logisticTerms.length === 1) {
        shippingChargePercentages = logisticTerms[0].shippingValue;
      }

      shippingChargePercentages = logisticTerms[0].shippingValue;
      var shippingCharge = calculateTotal * (shippingChargePercentages / 100);

      return shippingCharge;
    },
    differentStatusHasText() {
      let textInfo = {
        title: "",
        content: "",
        buttonList: []
      }
      if (this.order && this.order.status) {
        // when this
        if (this.isBrand || this.$route.name === "adminBrandOrder") {
          // 1: retailer create new order, need brand accept
          if (
            this.order.status.id === this.orderStatus.pending &&
            !this.order.brandAccepted &&
            this.order.retailerAccepted &&
            this.order.version === "2"
          ) {
            textInfo.title = "You have a new order!"
            textInfo.content = "Please confirm, request changes, or cancel your order."
            textInfo.buttonList = [
              { name: "ACCEPT ORDER", isAccept: true, status: this.orderStatus.confirmed },
              { name: "EDIT ORDER", isEdit: true },
              { name: "CANCEL ORDER", status: "canceled" }
            ]
          }
          // 2. retailer edit, need brand edit/accept
          if (
            this.order.status.id === this.orderStatus.pending &&
            !this.order.brandAccepted &&
            this.order.retailerAccepted &&
            this.order.version !== "2"
          ) {
            textInfo.title = "Your order has been edited."
            textInfo.content = `Please review and respond. Ask questions or request changes using order Comments.`
            textInfo.buttonList = [
              { name: "ACCEPT CHANGES", isAccept: true, status: this.orderStatus.confirmed },
              { name: "EDIT ORDER", isEdit: true },
              {
                name: "DECLINE CHANGES",
                status: this.order.status.id,
                isDecline: true
              },
              { name: "CANCEL ORDER", status: "canceled" }
            ]
          }
          // 3. brand edit, need retailer edit/accept
          if (
            this.order.status.id === this.orderStatus.pending &&
            this.order.brandAccepted &&
            !this.order.retailerAccepted &&
            this.order.version !== "2"
          ) {
            textInfo.title = "Your edits have been sent."
            textInfo.content = `Your edits have been sent to ${this.retailer.name}. ${this.retailer.name} will review and respond`
          }
          // 4 brand/retailer=> decline
          if (this.order.declined && !this.order.brandAccepted) {
            textInfo.title = "Requests declined. "
            textInfo.content = `${this.retailer.name} has declined your edits.`
          }
          if (this.order.declined && this.order.brandAccepted) {
            textInfo.title = "You have declined change requests. "
          }
          if (this.order.status.id === this.orderStatus.canceled) {
            textInfo.title = "Order canceled. "
            textInfo.content = `${this.order.brandAccepted ? "you" : this.retailer.name
              } has canceled this order. `
          }
          if (this.order.status.id === this.orderStatus.confirmed) {
            textInfo.title = "Next step: Ship your order."
            textInfo.content = `Congratulations, your order is confirmed. A packing list is now available for download. Please click “ADD SHIP DETAILS” to submit shipping details to ${this.retailer.name}.`
            textInfo.buttonList = [
              { name: "ADD SHIP DETAILS", isAddShip: true, status: this.orderStatus.delivered }
            ]
          }
          // shipped and invoiced
          if (
            this.order.status.id === this.orderStatus.supplyShipped ||
            this.order.status.id === this.orderStatus.warehouse ||
            this.order.status.id === this.orderStatus.deliverShipped ||
            this.order.status.id === this.orderStatus.delivered
          ) {
            textInfo.title = "Your order has been shipped."
            textInfo.content = `An invoice is now available for download.`
          }
          if (
            this.order.status.id === this.orderStatus.paidToBrand ||
            this.order.status.id === this.orderStatus.paidByRetailer
          ) {
            textInfo.title = "Your order has been paid."
            textInfo.content = `Payment Advice is now available for download. This order is now complete.`
          }
        }
        // When the account is retailer
        if (this.isRetailer || this.$route.name === "adminRetailerOrder") {
          // 1.retailer create new order
          if (
            this.order.status.id === this.orderStatus.pending &&
            !this.order.brandAccepted &&
            this.order.retailerAccepted &&
            this.order.version === "2"
          ) {
            textInfo.title = "Thank you! "
            textInfo.content = `Your purchase order has been submitted to ${this.brand.name}. ${this.brand.name} will review and respond. `
            textInfo.buttonList = [{ name: "CANCEL ORDER", status: "canceled" }]
          }
          // 2.brand edit , need retailer edit/accept/cancel...
          if (
            this.order.status.id === this.orderStatus.pending &&
            this.order.brandAccepted &&
            !this.order.retailerAccepted &&
            !this.order.declined &&
            this.order.version !== "2"
          ) {
            textInfo.title = "Your order has been edited. "
            textInfo.content = `Please review and respond. Ask questions or request changes using order Comments. `
            textInfo.buttonList = [
              { name: "ACCEPT CHANGES", isAccept: true, status: this.orderStatus.confirmed },
              { name: "EDIT ORDER", isEdit: true },
              {
                name: "DECLINE CHANGES",
                status: this.order.status.id,
                isDecline: true
              },
              { name: "CANCEL ORDER", status: "canceled" }
            ]
          }
          // 2-1: if retailer edit, retailer view page
          if (
            this.order.status.id === this.orderStatus.pending &&
            !this.order.brandAccepted &&
            this.order.retailerAccepted &&
            !this.order.declined &&
            this.order.version !== "2"
          ) {
            textInfo.title = "Your edits have been sent. "
            textInfo.content = `Your change requests have been sent to ${this.brand.name}.  ${this.brand.name} will review and respond.`
          }
          if (this.order.declined && this.order.retailerAccepted) {
            textInfo.title = "You have declined change requests. "
          }
          if (this.order.declined && !this.order.retailerAccepted) {
            textInfo.title = "Order changes declined. "
            textInfo.content = `${this.brand.name} has declined the order change requests. `
          }
          if (this.order.status.id === this.orderStatus.confirmed) {
            textInfo.title = "Your order is confirmed."
            textInfo.content = `${this.brand.name} will let you know when the order has been shipped. `
          }
          if (
            this.order.status.id === this.orderStatus.supplyShipped ||
            this.order.status.id === this.orderStatus.warehouse ||
            this.order.status.id === this.orderStatus.deliverShipped ||
            this.order.status.id === this.orderStatus.delivered
          ) {
            textInfo.title = "Your order has shipped."
            textInfo.content = `An invoice is now available for download.`
          }
          if (
            this.order.status.id === this.orderStatus.paidByRetailer ||
            this.order.status.id === this.orderStatus.paidToBrand
          ) {
            textInfo.title = "Payment complete."
            textInfo.content = `Your payment for this order has been received.`
          }
          if (this.order.status.id === this.orderStatus.canceled) {
            textInfo.title = "Order canceled. "
            textInfo.content = `${this.order.retailerAccepted ? "you" : this.brand.name
              } have canceled this order. `
          }
        }
        // When the account is admin
        if (
          this.isAdmin &&
          this.$route.name !== "adminRetailerOrder" &&
          this.$route.name !== "adminBrandOrder"
        ) {
          // 1.retailer create order
          if (
            this.order.status.id === this.orderStatus.pending &&
            this.order.retailerAccepted &&
            !this.order.brandAccepted &&
            this.order.version === "2"
          ) {
            textInfo.title = `${this.brand.name} has received an order from ${this.retailer.name}.`
            textInfo.content = `${this.brand.name} is reviewing the order. `
            textInfo.buttonList = [
              { name: "EDIT ORDER", isEdit: true },
              { name: "DELETE ORDER", isDelete: true, status: this.orderStatus.deleted }
            ]
          }
          // 2.brand edit order
          if (
            this.order.status.id === this.orderStatus.pending &&
            this.order.brandAccepted &&
            !this.order.retailerAccepted &&
            !this.order.declined &&
            this.order.version !== "2"
          ) {
            textInfo.title = "Changes requested to order. "
            textInfo.content = `${this.brand.name} has submitted a change request and is waiting for a response. `
            textInfo.buttonList = [
              { name: "EDIT ORDER", isEdit: true },
              { name: "DELETE ORDER", isDelete: true, status: this.orderStatus.deleted }
            ]
          }
          // 3. retailer edit order
          if (
            this.order.status.id === this.orderStatus.pending &&
            !this.order.brandAccepted &&
            this.order.retailerAccepted &&
            !this.order.declined &&
            this.order.version !== "2"
          ) {
            textInfo.title = "Changes requested to order. "
            textInfo.content = `${this.retailer.name} has submitted a change request and is waiting for a response. `
            textInfo.buttonList = [
              { name: "EDIT ORDER", isEdit: true },
              { name: "DELETE ORDER", isDelete: true, status: this.orderStatus.deleted }
            ]
          }
          if (this.order.declined && !this.order.retailerAccepted) {
            textInfo.title = `${this.brand.name} declined change request. `
            textInfo.content = `${this.retailer.name} requested changes have been declined. `
            textInfo.buttonList = [
              { name: "DELETE ORDER", isDelete: true, status: this.orderStatus.deleted }
            ]
          }
          if (this.order.declined && this.order.retailerAccepted) {
            textInfo.title = `${this.retailer.name} declined change request. `
            textInfo.content = `${this.brand.name} requested changes have been declined. Waiting for ${this.brand.name}'s response.   `
            textInfo.buttonList = [
              { name: "DELETE ORDER", isDelete: true, status: this.orderStatus.deleted }
            ]
          }
          if (this.order.status.id === this.orderStatus.canceled && this.order.retailerAccepted) {
            textInfo.title = `Order canceled. `
            textInfo.content = `${this.retailer.name} has cancelled this order.`
          }
          if (this.order.status.id === this.orderStatus.canceled && !this.order.retailerAccepted) {
            textInfo.title = `Order canceled. `
            textInfo.content = `${this.brand.name} has cancelled this order.`
          }
          if (this.order.status.id === this.orderStatus.confirmed) {
            textInfo.title = "Order has been confirmed. "
            textInfo.content = `Both ${this.retailer.name} and ${this.brand.name} have accepted the terms of this order. If fulfilled through Landing, mark as shipped.`
            textInfo.buttonList = [
              { name: "MARK AS SHIPPED", isShipped: true, status: this.orderStatus.delivered }
            ]
          }
          if (
            this.order.status.id === this.orderStatus.supplyShipped ||
            this.order.status.id === this.orderStatus.warehouse ||
            this.order.status.id === this.orderStatus.deliverShipped ||
            this.order.status.id === this.orderStatus.delivered
          ) {
            textInfo.title = "Next step: Mark as paid."
            textInfo.content = `Order has been shipped and invoice is now available for download. Mark as paid once payment is received from ${this.retailer.name}. `
            textInfo.buttonList = [
              { name: "MARK AS PAID ", isShipped: true, status: this.orderStatus.paidByRetailer }
            ]
          }
          if (this.order.status.id === this.orderStatus.paidByRetailer) {
            textInfo.title = `Next step: Mark as paid to Brand.`
            textInfo.content = `Payment for this order has been received. Pay Brand and update order status. `
            textInfo.buttonList = [
              { name: "PAYMENT ADVICE ", isShowAdvice: true },
              { name: "PAID TO BRAND", isShipped: true, status: this.orderStatus.paidToBrand }
            ]
          }
          if (this.order.status.id === this.orderStatus.paidToBrand) {
            textInfo.title = `Paid to ${this.brand.name}.`
            textInfo.content = `This order is now complete.`
          }
        }
      }

      return textInfo
    },
    showAddTrackingInfo() {
      if (this.order.airShipments && this.order.oceanShipments) {
        return this.order.airShipments.length + this.order.oceanShipments.length == 2
      } else {
        return (
          (this.order.airShipments && this.order.airShipments.length == 2) ||
          (this.order.oceanShipments && this.order.oceanShipments.length == 2)
        )
      }
    },
    pendingStatus() {
      return this.order.status && this.order.status.id === "pending"
    },
    statusItems() {
      return this.order.lifecycle && Object.values(this.order.lifecycle).map(item => item.status)
    },
    updatedStatusDate() {
      if (this.order.lifecycle) {
        const currentStatus = this.order.lifecycle.filter(
          item => item.status.id === this.order.status.id
        )
        if (currentStatus[0] && currentStatus[0].transitionedAt) {
          return new Date(currentStatus[0].transitionedAt)
        } else {
          return null
        }
      }
      return null
    },
    retailerOrBrandInfo() {
      return this.$store.getters["retailer/retailer"]
    },
    feLifecycle() {
      let list = this.order.newFeLifecycle
      if (
        (this.$route.name === "adminBrandOrder" || this.$route.name === "adminRetailerOrder") &&
        this.role === "admin"
      ) {
        list.pop()
      }
      return list
    }
  },
  methods: {
    async openDialog() {
      this.printOrderDetailVisible = true
      await this.fetchData()
      // this.initStatusTxt()
      await this.$store.dispatch("order/getOrder", this.$route.params.id)
      this.markAsList()
    },
    async fetchData() {
      await this.$store.dispatch("order/getOrder", this.$route.params.id)
      this.purchaseDetails = this.orderDetails()
      this.updateRetailerTerms()
      this.$store.dispatch("retailer/fetchRetailer", this.order.retailer.id)
      this.$store.dispatch("brand/getBrand", this.order.brand.id)
      this.$store.dispatch("reference/setReference")
    },
    async updateRetailerTerms(charges, isSubmit) {
      let chargeList = charges ? charges : this.order.charges
      if (chargeList && chargeList.length === 0) {
        return
      }
      if (this.isRetailer) {
        chargeList = chargeList.filter(
          item =>
            item.seenByRetailer && item.ownedBy.id === "retailer" && item.paidBy.id === "brand"
        )
      } else if (this.isBrand) {
        chargeList = chargeList.filter(
          item => item.seenByBrand && item.ownedBy.id === "retailer" && item.paidBy.id === "brand"
        )
      } else if (this.isAdmin) {
        chargeList = chargeList.filter(
          item => item.seenByLanding && item.ownedBy.id === "retailer" && item.paidBy.id === "brand"
        )
      }
      chargeList = chargeList.map(item => {
        if (item.percentage) {
          item.calculatedCostUsd = (Number(item.recordedValue) / 100) * this.productTotal
        }
        return item
      })
      this.retailerLists = chargeList
      this.retailerTermsTotal = chargeList.reduce((total, charge) => {
        if (charge.paidBy.id == "brand") {
          return total - parseFloat(charge.calculatedCostUsd)
        } else {
          return total + parseFloat(charge.calculatedCostUsd)
        }
      }, 0)
      this.purchaseDetails = this.orderDetails()
      if (!isSubmit) {
        return
      }
      const acceptInfo = this.formatUsersAccepted()
      let order = {
        ...acceptInfo,
        id: this.order.id,
        status: {
          id: this.order.status.id
        },
        version: Number(this.order.version) + 1,
        charges
      }
      try {
        await this.fetchUploadOrder(order)
        this.purchaseDetails = this.orderDetails()
        this.updateRetailerTerms()
      } catch (error) {
        console.error(error)
      }
    },
    async getProductEditList(sendList) {
      const acceptInfo = this.formatUsersAccepted()
      const items = sendList
      let order = {
        ...acceptInfo,
        id: this.order.id,
        status: {
          id: this.order.status.id
        },
        version: Number(this.order.version) + 1,
        items
      }
      try {
        await this.fetchUploadOrder(order)
        this.purchaseDetails = this.orderDetails()
        this.updateRetailerTerms()
      } catch (error) {
        console.error(error)
      }
    },
    getRetailerTermsTotal(trtailerTermsTotal) {
      this.trtailerTermsTotal = trtailerTermsTotal
    },
    editTrackingInfo(status) {
      this.trackingStatus = status
      this.isEditTrackingInfo = true
      this.airShipments = this.order.airShipments && [
        ...this.order.airShipments.map(item => {
          return {
            orderStatus: { ...item.orderStatus },
            provider: { ...item.provider },
            trackingId: item.trackingId
          }
        })
      ]
      this.oceanShipments = this.order.oceanShipments && [
        ...this.order.oceanShipments.map(item => {
          return {
            orderStatus: { ...item.orderStatus },
            provider: { ...item.provider },
            ...item
          }
        })
      ]
      // this.showTrackingInfo = true
      // this.openShippedModal()
    },
    showTrackingInfoModal() {
      if (this.role == "brand") {
        this.trackingStatus = this.orderStatus.supplyShipped
      } else if (
        this.role == "admin" &&
        (this.supplyAirTrackingInfo || this.supplyOceanTrackingInfo)
      ) {
        this.trackingStatus = this.orderStatus.deliverShipped
      } else if (
        this.role == "admin" &&
        (this.deliveryAirTrackingInfo || this.deliveryOceanTrackingInfo)
      ) {
        this.trackingStatus = this.orderStatus.deliverShipped
      } else {
        this.trackingStatus = ""
      }
      this.airShipments = this.order.airShipments && [
        ...this.order.airShipments.map(item => {
          return {
            orderStatus: { ...item.orderStatus },
            provider: { ...item.provider },
            trackingId: item.trackingId
          }
        })
      ]
      this.oceanShipments = this.order.oceanShipments && [
        ...this.order.oceanShipments.map(item => {
          return {
            orderStatus: { ...item.orderStatus },
            provider: { ...item.provider },
            ...item
          }
        })
      ]
      this.isEditTrackingInfo = false
      // this.showTrackingInfo = true
      // this.openShippedModal()
    },
    orderDetails() {
      let purchase =
        this.order &&
        this.order.items &&
        this.order.items.filter(item => item.quantityType.id === "purchase")
      let testers =
        this.order &&
        this.order.items &&
        this.order.items.filter(item => item.quantityType.id === "testers")
      if (purchase && purchase.length !== 0 && testers && testers.length.length !== 0) {
        purchase = purchase.map(purchaseElement => {
          testers.forEach(testerElement => {
            if (purchaseElement.product.id == testerElement.product.id) {
              purchaseElement.testersquantity = testerElement.quantity || 0
              purchaseElement.adjustedTestersQuantity =
                testerElement.adjustedQuantity || testerElement.adjustedQuantity === 0
                  ? testerElement.adjustedQuantity
                  : testerElement.quantity
              purchaseElement.testerStock = testerElement.stock || 0
            }
          })
          if (
            purchaseElement.adjusterByClientType &&
            Object.keys(purchaseElement.adjusterByClientType).length !== 0
          ) {
            purchaseElement.textColor =
              purchaseElement.adjusterByClientType.id === "retailer" ? "#FEC206" : "#7ACE93"
          } else {
            purchaseElement.textColor = "#7ACE93"
          }
          return purchaseElement
        })
      }
      return purchase
    },

    countryNameById(countryId) {
      const country = this.countries.find(country => country.id === countryId)
      return country && country.name
    },
    async acceptOrder(state) {
      let order = {
        id: this.order.id,
        version: Number(this.order.version) + 1,
        isBrandAccepted: true,
        isRetailerAccepted: true,
        isLandingAccepted: true,
        status: {
          id: state
        }
      }
      try {
        await this.fetchUploadOrder(order)
      } catch (error) {
        console.error(error)
      }
    },
    async fetchUploadOrder(order) {
      let params = {}
      if (this.$route.name == "adminBrandOrder") {
        params = { "_jwt-c_id": this.order.brand.id, "_jwt-c_type": "brand" }
      }
      if (this.$route.name == "adminRetailerOrder") {
        params = { "_jwt-c_id": this.order.retailer.id, "_jwt-c_type": "retailer" }
      }
      await this.$store.dispatch("order/updateStatus", { order, params })
      await this.$store.dispatch("order/getOrder", this.$route.params.id)
    },
    formatUsersAccepted() {
      let obj = {
        isBrandAccepted: false,
        isRetailerAccepted: false,
        isLandingAccepted: false
      }
      if (this.isAdmin) {
        obj.isBrandAccepted = true
        obj.isRetailerAccepted = false
        obj.isLandingAccepted = false
      }
      if (this.isBrand) {
        obj.isBrandAccepted = true
        obj.isRetailerAccepted = false
        obj.isLandingAccepted = false
      }
      if (this.isRetailer) {
        obj.isBrandAccepted = false
        obj.isRetailerAccepted = true
        obj.isLandingAccepted = false
      }
      return obj
    },
    transitionObject(nextStatus, isDeclined) {
      const acceptInfo = this.formatUsersAccepted()
      let result = {
        ...acceptInfo,
        id: this.order.id,
        status: {
          id: nextStatus
        },
        version: Number(this.order.version) + 1
      }
      // if (nextStatus == this.orderStatus.pending) {
      //   result.isBrandAccepted = false
      //   result.isRetailerAccepted = false
      // }
      if (isDeclined) {
        result.declined = isDeclined
      }
      if (nextStatus === this.orderStatus.paidToBrand) {
        result.paymentAdviceStatus = true
      }
      return result
    },
    async statusTransition(Status, otherValue, isDeclined) {
      let nextStatus = otherValue ? otherValue : Status
      let order = this.transitionObject(nextStatus, isDeclined)
      if (nextStatus === "deleted" || nextStatus === "canceled" || isDeclined) {
        this.openDeleteDialog(order, nextStatus)
        return
      }
      if (
        (nextStatus === this.orderStatus.supplyShipped &&
          !this.supplyAirTrackingInfo &&
          !this.supplyOceanTrackingInfo) ||
        (nextStatus === this.orderStatus.deliverShipped &&
          !this.deliveryAirTrackingInfo &&
          !this.deliveryOceanTrackingInfo) ||
        (nextStatus === this.orderStatus.delivered &&
          !this.deliveryAirTrackingInfo &&
          !this.deliveryOceanTrackingInfo)
      ) {
        // this.$refs["addShipDetail"].openDialog(this.order, nextStatus, this.buttonColor)
        // this.showTrackingInfoModal()
        return
      }
      try {
        await this.fetchUploadOrder(order)
      } catch (error) {
        console.error(error)
      }
    },
    async reStatusTransition(Status) {
      const otherCharge = this.order.charges.filter(
        item =>
          item.ownedBy.id === "brand" &&
          item.paidBy.id === "landing" &&
          item.chargeType.id === "custom"
      )
      let charge = this.order.charges.filter(
        item =>
          !(
            item.ownedBy.id === "brand" &&
            item.paidBy.id === "landing" &&
            item.chargeType.id === "custom"
          )
      )
      if (otherCharge && otherCharge.length !== 0) {
        otherCharge[0].addedBy = {
          id: this.isAdmin ? "landing" : "brand"
        }
        otherCharge[0].calculatedCostUsd = 0
        otherCharge[0].recordedValue = 0
        charge.push(otherCharge[0])
      }
      const order = {
        id: this.order.id,
        status: {
          id: Status
        },
        charges: charge,
        paymentAdviceStatus: false
      }
      try {
        await this.fetchUploadOrder(order)
      } catch (error) {
        console.error(error)
      }
    },
    openDeleteDialog(order, status) {
      let text = ""
      let text1 = ""
      if (status === "deleted") {
        text = "DELETE"
        text1 = "delete"
      } else if (status === "canceled") {
        text = "CANCEL"
        text1 = "cancel"
      } else {
        text = "DECLINE"
        text1 = "decline"
      }
      let classText
      if (this.isAdmin) classText = "delete-dialog-tips-admin"
      if (this.isBrand) classText = "delete-dialog-tips-brand"
      if (this.isRetailer) classText = "delete-dialog-tips-retailer"
      const _this = this
      this.$confirm({
        title: `Are you sure you wish to ${text1} this order?`,
        content: "This action cannot be undone.",
        cancelText: `NO, DON’T ${text}`,
        okText: `YES, ${text}`,
        class: classText,
        iconType: "exclamation-circle",
        async onOk() {
          await _this.fetchUploadOrder(order)
        }
      })
    },
    decreaseQuantity(item) {
      if (item.quantity > 1) {
        item.quantity = Number(item.quantity) - 1
        this.isSaveDisabled = false
      }
    },
    increaseQuantity(item) {
      if (item.quantity < this.initialQuantity) {
        item.quantity = Number(item.quantity) + 1
      }
      if (item.quantity == this.initialQuantity && item.testersquantity == this.initialTesters) {
        this.isSaveDisabled = true
      }
    },
    updateQuantitiyObject() {
      return {
        id: this.order.id,
        items: this.order.items,
        version: Number(this.order.version) + 1,
        isRetailerAccepted: false
      }
    },
    async updataEditProduct(order) {
      await this.fetchUploadOrder(order)
    },
    updateShippingAddress(addressModal) {
      let order = {
        id: this.order.id,
        shipToAddress: addressModal,
        isRetailerAccepted: this.order.retailerAccepted,
        isBrandAccepted: false,
        version: Number(this.order.version) + 1
      }
      this.updateShippingDetails(order)
    },
    updateContactInfo(updatedContact) {
      let order = {
        id: this.order.id,
        contact: updatedContact,
        isRetailerAccepted: this.order.retailerAccepted,
        isBrandAccepted: false,
        version: Number(this.order.version) + 1
      }
      this.updateShippingDetails(order)
    },
    updateShippingWindow(shipDate, cancelDate) {
      let order = {
        id: this.order.id,
        shipDate: shipDate,
        cancelDate: cancelDate,
        version: Number(this.order.version) + 1,
        isBrandAccepted: this.isBrand ? true : false,
        isRetailerAccepted: this.isBrand ? false : true
      }
      this.updateShippingDetails(order)
    },
    async updateShippingDetails(order) {
      try {
        await this.fetchUploadOrder(order)
      } catch (error) {
        console.error(error)
      }
    },
    // order status
    markAsList() {
      const status = this.order.status.id || "pending"
      const list = this.markAsTabText.filter(item => item.status === status)
      let result = this.markAsTabText.map(value => {
        if (list[0].nextStatus === value.status || list[0].reStatus === value.status) {
          value.canClick = false
        } else {
          value.canClick = true
        }
        return value
      })
      // If it is not in these two states, it means that there is no need to roll back the order state, so invoiced is not displayed
      if (
        !(status === this.orderStatus.paidToBrand || status === this.orderStatus.paidByRetailer)
      ) {
        result = result.filter(item => item.id !== 4)
      }
      this.list = result
    },
    updateStatus() {
      this.$emit("statusUpdated")
    },
    formattedDate() {
      return this.order.createdAt && new Date(this.order.createdAt)
    },
    filledStatus(statusId) {
      const statusItemsNames = this.statusItems.map(item => item.id)
      let indexOfActiveStatus = statusItemsNames.indexOf(this.order.status.id)
      if (this.role === "brand" && this.order.status.id === this.orderStatus.paidByRetailer) {
        indexOfActiveStatus = statusItemsNames.indexOf(this.orderStatus.paidToBrand) - 1
      }
      return (
        statusItemsNames.indexOf(statusId) <= indexOfActiveStatus ||
        this.order.status.id == this.orderStatus.paidToBrand
      )
    },
    onClickStatus(status, isCanClick, reStatus) {
      if (isCanClick) {
        return
      }
      if (this.order.status.id === this.orderStatus.paidToBrand && reStatus) {
        this.$emit("reStatusTransition", status)
      } else if (
        this.order.status.id === this.orderStatus.paidByRetailer &&
        reStatus &&
        reStatus !== this.orderStatus.paidByRetailer
      ) {
        this.$emit("reStatusTransition", reStatus)
      } else {
        this.$emit("statusUpdated", status)
      }
    },
    clickPrint() {
      this.isPrint = !this.isPrint
      this.isShowDownloadDropDown = false
    },
    clickDownload() {
      this.isShowDownloadDropDown = !this.isShowDownloadDropDown
      this.isPrint = false
    },
    closeOrderModal() {
      this.printOrderDetailVisible = false
    },
    printOrderModal() {
      const btns = document.getElementsByClassName("print-order-button")
      if (btns && btns.length > 0) {
        for (let i = 0; i < btns.length; i++) {
          btns[i].style.display = "none"
        }
      }
      // this.onBeforePrint()
      print({
        printable: "retailerOrderPrintMain",
        type: "html",
        // style: style,// 亦可使用引入的外部css;
        css: "/print-order.css",
        scanStyles: false
      })
      if (btns && btns.length > 0) {
        for (let i = 0; i < btns.length; i++) {
          btns[i].style.display = "inline-block"
        }
      }
    }
    // retailerOrderPrintMain
    // onBeforePrint() {
    //   const style = document.createElement("style")
    //   const mapHeight = document.getElementsByClassName("retailer-order-print")[0].style.height
    //   let marginTops = "0mm"
    //   if (mapHeight < 800) {
    //     const temp = 800 - parseInt(mapHeight)
    //     marginTops = `${(temp / 8).toFixed(2)}mm`
    //   }
    //   style.innerHTML = `@page{size: portrait;margin:auto 0mm;margin-top: ${marginTops};page-break-after:always}`
    //   document.getElementsByTagName("body")[0].style.zoom = 0.9
    //   window.document.head.appendChild(style)
    //   window.print()
    // }
  }
}
</script>

<style lang="sass">
.retailer-order-print
  height: auto
  //padding-top: 40px
  //padding-bottom: 80px
  //padding: 40px 122px 80px 122px

  .retailer-order-status-banner__block
    display: flex
    //justify-content: space-between
    align-items: center
    padding-right: 20px

    .retailer-order-status-banner__left-block
      margin-bottom: 0px

    .retailer-order-status-banner__button-block
      margin-left: auto
      margin-top: 10px
      display: flex
      align-items: center
      flex-wrap: wrap-reverse

      .button
        margin-bottom: 10px

      .button.button
        margin-left: 10px

  .retailer-order-table__container
    .retailer-order-table
      padding: 20px 0px

      .reretailer-order-table__header-container
        .button[disabled]
          cursor: default
        display: flex
        justify-content: space-between

        .add-charge-button
          margin-right: 10px

      .retailer-order-table__cell
        color: #333
        font-size: 14px
        font-family: "Proxima Nova", "Roboto", sans-serif

        .additional-charge
          width: 100%

          select
            width: 100%
            height: 36px

        .amount-input-container
          display: flex
          margin-bottom: 7px

          .input
            width: 70px
            margin-right: 4px

          .additional-charge-dropdown
            .select
              height: 36px

              select
                height: 100%

        .additional-charge-buttons
          width: 129px
          display: flex
          justify-content: flex-end
          padding: 5px 0

          .button[disabled]
            cursor: default

          .save-additional-charge
            margin-right: 10px
            font-size: 14px
            height: 25px
            width: 50px

          .cancel-additional-charge
            font-size: 14px
            height: 25px
            width: 50px
            text-decoration: underline
            color: #4250C7
            border: none

        .retailer-order-quantity-input
          text-align: right !important
          width: 70px

          .retailer-order-input
            width: 74px
            height: 41px
            position: relative
            text-align: center

          .retailer-order-input-plus
            position: absolute
            top: 0px
            right: 0px
            border-left: 1px solid #E5E5E5
            border-bottom: 1px solid #E5E5E5
            height: 21px
            width: 21px
            display: flex
            align-items: center
            justify-content: center
            cursor: pointer

          .retailer-order-input-minus
            position: absolute
            top: 20px
            right: 0px
            border-left: 1px solid #E5E5E5
            height: 21px
            width: 21px
            display: flex
            align-items: center
            justify-content: center
            cursor: pointer

        .product-edit-buttons
          display: flex
          justify-content: flex-start
          padding: 25px 0 0 0

          .button[disabled]
            cursor: default

          .save-additional-charge
            margin-right: 10px
            font-size: 14px
            height: 25px
            width: 50px

          .cancel-additional-charge
            font-size: 14px
            height: 25px
            width: 50px
            text-decoration: underline
            color: #4250C7
            border: none

      .table-border-b-none
        border-bottom: none !important

      .retailer-order-table__cell.subtotal
        font-weight: 700

      .brand-order-table__cell.subtotal
        font-weight: 700

      .total
        padding-top: 30px

        td
          padding: 30px 16px 16px 16px

    .retailer-order-table__cell.table-border-b-none
      border-bottom: none

    .brand-order-table__cell.table-border-b-none.text-right
      text-align: right

    .brand-order-table__cell.table-border-b-none
      border-bottom: none

    .retailer-order-table__cell.table-border-b-none.text-right
      text-align: right

    .retailer-order-table.table-border-t-none
      padding-top: 46px
      margin-top: 10px
  //border-top: none


  &-navigation
    font-size: 14px
    color: #4A90E2

    span:first-child
      padding-right: 20px

  &-id
    padding-top: 10px
    padding-bottom: 10px
    font-size: 28px

  &-table
    border: 1px solid #E0E0E0
    padding: 20px

    &__top
      display: flex

    &__name
      color: #333333
      font-size: 24px
      font-weight: 500
      letter-spacing: 0.25px
      margin-bottom: 30px
      margin-left: 10px

    &__edit
      color: #262626
      margin-left: 30px
      font-size: 14px
      line-height: 35px
      text-decoration: underline
      cursor: pointer

    &__head
      color: #888888 !important
      font-size: 14px
      font-weight: 500
      border-bottom: 1px solid #E0E0E0 !important

    &__cell
      color: #333333 !important
      font-size: 14px
      font-weight: 500
      border-bottom: 1px solid #E0E0E0 !important
      margin-top: 10px
      position: relative

    &__container
      margin-right: 20px

  &-information
    border: 1px solid #E0E0E0

    &__title
      color: #333333
      font-size: 16px
      padding-bottom: 20px

    &__container
      padding: 20px
      border-bottom: 1px solid #E0E0E0

    &__link
      color: #006FBB
      font-weight: 600
      font-size: 14px

    &__header
      text-transform: uppercase
      color: #888888
      font-size: 12px
      padding-bottom: 10px

    &__logo
      width: 30px
      height: 30px

    &__terms
      margin-top: 20px

  &-status-banner
    background-color: rgba(74, 138, 93, 0.11)
    //border: 1px solid #E0E0E0
    border-radius: 0 0 3px 3px
    //border-top: 3px solid #9A8A8A
    min-height: 112px
    width: 100%
    margin-bottom: 20px
    padding-left: 20px

    &__title
      padding-top: 4px
      font-size: 16px
      color: #212B36

    &__details
      font-size: 14px
      color: #212B36

    &__icon
      display: flex
      padding-right: 10px

  &-calculations

    &__subtotal
      display: flex
      justify-content: flex-end
      padding-bottom: 20px

      &-amount
        padding-left: 45px

    &__full-subtotal
      display: flex
      justify-content: flex-end
      border-top: 1px solid #E0E0E0
      padding-top: 15px

      &-amount
        padding-left: 45px
        border-top: 1px solid #E0E0E0
        padding-top: 15px

    &__full-subtotal-title
      font-weight: bold
      color: #333333

  .retailer-order-information__container
    .shipping-first-block
      width: 100%

      .hipping-first-block__heading
        display: flex
        align-items: center

        .retailer-order-information__title
          padding-bottom: 0px

        .img-block
          height: 30px
          margin-left: auto

          img
            height: 30px

    .retailer-order-information__header
      color: #888

      .adresss
        padding-top: 16px

        p
          color: #333333
          font_size: 14px

  .order-product-item__dropdown
    position: absolute
    width: 128px
    top: 10px
    right: 10px
    z-index: 100
    background-color: #ffffff
    border: 1px solid #DADADA

  .order-product-item__dropdown-item
    height: 45px
    display: flex
    align-items: center
    padding-left: 10px
    cursor: pointer

  .order-product-item__dropdown-icon
    padding-right: 15px

  .order-product-item__dropdown-item:hover
    background-color: #F5F6F8

  .order-product-item__remove-button
    color: #BF0711

  .order-product-item__circles
    position: absolute
    top: 15px
    right: 0px
    z-index: 1
    cursor: pointer
    height: 30px
    width: 30px

  .alignTextCenter
    text-align: center !important

  .edit-shipment-info
    margin-left: auto
    text-decoration: underline
    color: #333333 !important
    font-size: 14px

  .shipment-provider
    font-size: 14px
    margin-bottom: 17px

  .order-information-subheading
    display: flex

    span
      color: #000000
      font-size: 16px

  .button-new-color
    width: 153px
    background-color: #4a8a5d
    color: white

  .icon-tips-style
    font-size: 44px
    color: #7ACE93

  .total-amount-number
    padding: 20px 25px
    margin-top: 10px
    border: 1px solid #D9D9D9
    font-size: 14px
    font-weight: 700
    font-family: "Proxima Nova", "Roboto", sans-serif
    color: #000000
    text-align: right

    span
      margin-left: 40px

  .edit-params-product
    color: #7ACE93
    font-size: 14px
    margin-bottom: 0

  .edit-params-product-color
    color: #7ACE93

  .additional-charge-tips-text
    margin: 9px 0
    display: flex
    justify-content: flex-start
    align-items: center

  .download-estimated-payment
    font-size: 14px
    text-decoration: underline
    font-weight: normal

  .download-estimated-payment:hover
    color: white
    background-color: #4a8a5d
    padding: 5px 7px
    font-size: 14px
    text-decoration: none
    border-radius: 2px
    font-weight: normal

.delete-dialog-tips-admin
  .ant-btn-primary
    background-color: #ff6132 !important
    border-color: #ff6132 !important

.delete-dialog-tips-brand
  .ant-btn-primary
    background-color: #4a8a5d !important
    border-color: #4a8a5d !important

.delete-dialog-tips-retailer
  .ant-btn-primary
    background-color: #FEC206 !important
    border-color: #FEC206 !important

.sku-style
  width: 160px
.print-button
  text-align: right
  //.retailer-order-print
  //  overfolw-x: hidden
  //  width: 1000px
.retailer-order-status-bar__creation-date
    min-height: 40px
    //padding-top: 20px
    margin-top: -24px
    color: #000000
    font-size: 20px
    font-weight: 300
    //margin-bottom: 30px
    font-family: "Proxima Nova", "Roboto", sans-serif
</style>
