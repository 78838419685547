<template>
    <div class="retailer-order-table table-border-t-none">
        <div class="reretailer-order-table__header-container">
            <p class="retailer-order-table__name">
                Shipping Charge
            </p>


        </div>
        <table class="table is-fullwidth">
            <thead>
                <tr>
                    <th class="retailer-order-table__head"></th>
                    <th class="retailer-order-table__head shipping-charge-amount">{{
                        $t('Retailers.RetailerOrder.amount') }}</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="retailer-order-table__cell"></td>
                    <td class="retailer-order-table__cell shipping-charge-amount">{{
                        priceFormat(calculateShippingCharge) }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import { priceFormat } from "@/utils/validate"
export default {
    name: "ShippingCharge",
    props: {
        shippingCharge: {
            type: Number,
            default: 0
        },
    },
    data() {
        return {
            priceFormat
        }
    },
    created() {

        console.log(this.shippingCharge)
    },
    computed: {
        calculateShippingCharge() {
            return this.shippingCharge;
        }
    },


}
</script>
<style lang="scss">
.shipping-charge-amount {
    width: 95px;
    text-align: left;
}
</style>